import _tap from "lodash/tap";
import _map from "lodash/map";
import _toPairs from "lodash/toPairs";
import _fromPairs from "lodash/fromPairs";
import _mergeWith from "lodash/mergeWith";
import _flatMap from "lodash/flatMap";
import _isEmpty from "lodash/isEmpty";
import _isArray from "lodash/isArray";
import _isFunction from "lodash/isFunction";
import _isUndefined from "lodash/isUndefined";
import _isPlainObject from "lodash/isPlainObject";
import _defaultOptions from "./defaultOptions";
import _miniSvgDataUri from "mini-svg-data-uri";
import _traverse from "traverse";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
const tap = _tap;
const map = _map;
const toPairs = _toPairs;
const fromPairs = _fromPairs;
const mergeWith = _mergeWith;
const flatMap = _flatMap;
const isEmpty = _isEmpty;
const isArray = _isArray;
const isFunction = _isFunction;
const isUndefined = _isUndefined;
const isPlainObject = _isPlainObject;
const defaultOptions = _defaultOptions;
const svgToDataUri = _miniSvgDataUri;
const traverse = _traverse;

function merge(...options) {
  function mergeCustomizer(objValue, srcValue, key, obj, src, stack) {
    if (isPlainObject(srcValue)) {
      return mergeWith(objValue, srcValue, mergeCustomizer);
    }

    return Object.keys(src).includes(key) // Convert undefined to null otherwise lodash won't replace the key
    // PostCSS still omits properties with a null value so it behaves
    // the same as undefined.
    ? srcValue === undefined ? null : srcValue : objValue;
  }

  return mergeWith({}, ...options, mergeCustomizer);
}

function flattenOptions(options) {
  return merge(...flatMap(toPairs(options), ([keys, value]) => {
    return fromPairs(keys.split(", ").map(key => [key, value]));
  }));
}

function resolveOptions(userOptions) {
  return merge({
    default: defaultOptions
  }, fromPairs(map(userOptions, (value, key) => [key, flattenOptions(value)])));
}

function replaceIconDeclarations(component, replace) {
  return traverse(component).map(function (value) {
    if (!isPlainObject(value)) {
      return;
    }

    if (Object.keys(value).includes("iconColor") || Object.keys(value).includes("icon")) {
      const {
        iconColor,
        icon,
        ...rest
      } = value;
      this.update(merge(replace({
        icon,
        iconColor
      }), rest));
    }
  });
}

exports = function ({
  addUtilities,
  addComponents,
  theme,
  postcss
}) {
  function addInput(options, modifier = "") {
    if (isEmpty(options)) {
      return;
    }

    addComponents({
      [`.form-input${modifier}`]: options
    });
  }

  function addTextarea(options, modifier = "") {
    if (isEmpty(options)) {
      return;
    }

    addComponents({
      [`.form-textarea${modifier}`]: options
    });
  }

  function addMultiselect(options, modifier = "") {
    if (isEmpty(options)) {
      return;
    }

    addComponents({
      [`.form-multiselect${modifier}`]: options
    });
  }

  function addSelect(options, modifier = "") {
    if (isEmpty(options)) {
      return;
    }

    addComponents(replaceIconDeclarations({
      [`.form-select${modifier}`]: merge({
        "&::-ms-expand": {
          color: options.iconColor
        },
        ...(isUndefined(options.paddingLeft) ? {} : {
          "@media print and (-ms-high-contrast: active), print and (-ms-high-contrast: none)": {
            paddingRight: options.paddingLeft // Fix padding for print in IE

          }
        })
      }, options)
    }, ({
      icon = options.icon,
      iconColor = options.iconColor
    }) => {
      return {
        backgroundImage: `url("${svgToDataUri(isFunction(icon) ? icon(iconColor) : icon)}")`
      };
    }));
  }

  function addCheckbox(options, modifier = "") {
    if (isEmpty(options)) {
      return;
    }

    addComponents(replaceIconDeclarations({
      [`.form-checkbox${modifier}`]: merge({ ...(isUndefined(options.borderWidth) ? {} : {
          "&::-ms-check": {
            "@media not print": {
              borderWidth: options.borderWidth
            }
          }
        })
      }, options)
    }, ({
      icon = options.icon,
      iconColor = options.iconColor
    }) => {
      return {
        "&:checked": {
          backgroundImage: `url("${svgToDataUri(isFunction(icon) ? icon(iconColor) : icon)}")`
        }
      };
    }));
  }

  function addRadio(options, modifier = "") {
    if (isEmpty(options)) {
      return;
    }

    addComponents(replaceIconDeclarations({
      [`.form-radio${modifier}`]: merge({ ...(isUndefined(options.borderWidth) ? {} : {
          "&::-ms-check": {
            "@media not print": {
              borderWidth: options.borderWidth
            }
          }
        })
      }, options)
    }, ({
      icon = options.icon,
      iconColor = options.iconColor
    }) => {
      return {
        "&:checked": {
          backgroundImage: `url("${svgToDataUri(isFunction(icon) ? icon(iconColor) : icon)}")`
        }
      };
    }));
  }

  function registerComponents() {
    const options = resolveOptions(theme("customForms"));
    addInput(options.default.input);
    addTextarea(options.default.textarea);
    addMultiselect(options.default.multiselect);
    addSelect(options.default.select);
    addCheckbox(options.default.checkbox);
    addRadio(options.default.radio);
    Object.keys((({
      default: _default,
      ...rest
    }) => rest)(options)).forEach(key => {
      const modifier = `-${key}`;
      addInput(options[key].input || {}, modifier);
      addTextarea(options[key].textarea || {}, modifier);
      addMultiselect(options[key].multiselect || {}, modifier);
      addSelect(options[key].select || {}, modifier);
      addCheckbox(options[key].checkbox || {}, modifier);
      addRadio(options[key].radio || {}, modifier);
    });
  }

  registerComponents();
};

export default exports;